import { I18nextProvider } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import i18nInstance, { i18nData, i18nLabels, locales } from '../i18n/index'

const emptyLabels = {}

const ns = 'translation'
const init = (lang: string, labels: i18nLabels) => {
  if (labels?.[lang] && !i18nInstance.hasResourceBundle(lang, ns)) {
    i18nInstance.addResourceBundle(
      lang,
      ns,
      { ...locales?.[lang], ...labels?.[lang] },
      true,
      true
    )
  }
  if (i18nInstance.language !== lang) i18nInstance.changeLanguage(lang)
}

const I18nProvider = (props: { children: any; store?: i18nData }) => {
  const { children, store } = props

  const locale: string = store?.lang ?? ''
  const labels: i18nLabels = store?.labels ?? emptyLabels

  useState(() => init(locale, labels))
  useEffect(() => init(locale, labels), [locale, labels])

  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
}

export default I18nProvider
